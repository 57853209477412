<template>
  <a-card title="兑换码管理">
    <a-space direction="vertical">
      <a-alert message="兑换码生成后，请复制到其他地方保存，以免丢失" type="warning" show-icon />
      <a-input style="width: 480px;" v-model:value="cdk" readonly @click="handleCopyToClipboard"></a-input>
      <a-space>
        <a-button @click="handleRedemptionCode('membership_1_month')">一个月兑换码</a-button>
        <a-button @click="handleRedemptionCode('membership_3_month')">三个月兑换码</a-button>
        <a-button @click="handleRedemptionCode('membership_6_month')">六个月兑换码</a-button>
        <a-button @click="handleRedemptionCode('membership_1_year')">一年兑换码</a-button>
      </a-space>
    </a-space>
  </a-card>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { message } from 'ant-design-vue';
import axios from 'axios';

export default defineComponent({
  setup() {
    const cdk = ref('');

    const handleRedemptionCode = async (type) => {
      console.log('type: ', type);
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/cdk/create', {
          params: {
            type: type
          }
        })
        const data = response.data

        if (data.succeed) {
          console.log('code: ', data.data.code);
          cdk.value = data.data.code;
          await navigator.clipboard.writeText(cdk.value);
          message.success('生成成功，已复制到剪贴板');
        } else {
          message.error('生成失败: ' + data.message);
        }
      } catch (error) {
        if (error.response) {
          const response = error.response;
          console.log('response: ', response);
          if (response.status === 404) {
            message.error('生成失败：网络错误');
          }
          else {
            message.error('生成失败：' + response.data.message);
          }
        }
        else {
          console.log('error: ', error);
        }
      }
    }

    const handleCopyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(cdk.value);
        message.success('已复制到剪贴板');
      } catch (err) {
        console.error('复制失败: ', err);
      }
    }

    return {
      cdk,
      handleRedemptionCode,
      handleCopyToClipboard
    };
  }
})
</script>