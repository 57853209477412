<template>
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>

<script>
import { defineComponent } from 'vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default defineComponent({
  setup() {
    return {
      zhCN
    }
  }
})
</script>

<style>
body {
  margin: 0;
  padding: 0;
}
</style>