import { createApp } from 'vue';
import App from './App.vue';
import Antd from 'ant-design-vue';
import router from './router';
import 'ant-design-vue/dist/reset.css';
import axios from 'axios';

// Vue.config.productionTip = false;

axios.interceptors.request.use(
    config => {
        const apiToken = localStorage.getItem('authToken');
        if (apiToken) {
            console.log('token: ', apiToken);
            config.headers['Accept'] = 'application/json';
            config.headers['Authorization'] = `Bearer ${apiToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const app = createApp(App);
app.use(router);
app.use(Antd);
app.mount('#app');
