<template>
  <div class="product-item" :class="{ hover: isHovered, selected: isSelected }" @mouseover="isHovered = true"
    @mouseleave="isHovered = false" @click="$emit('select', index)">
    <div class="background"></div>
    <div class="product-info">
      <p class="product-name">{{ product.name }}</p>
      <p class="product-price">
        <span class="currency">￥</span>
        <span class="amount">{{ parseFloat(product.price).toString() }}</span>
      </p>
      <div v-if="product.discount_rate < 1" class="discount-view">{{ product.discount_rate * 10 }}折</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      product: Object,
      index: Number,
      isSelected: Boolean
    },
    data() {
      return {
        isHovered: false
      }
    }
  };
</script>

<style scoped>
  .product-item {
    width: 95px;
    height: 95px;
    position: relative;
    cursor: pointer;
  }

  .product-item .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-image: url('@/assets/app/payment/goods_box.png');
    /* 正常背景图 */
  }

  .product-item.hover .background {
    background-image: url('@/assets/app/payment/goods_box_h.png');
    /* 悬浮背景图 */
  }

  .product-item.selected .background {
    background-image: url('@/assets/app/payment/goods_box_s.png');
    /* 选中背景图 */
  }

  .product-info {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    text-align: center;
    margin: 0;
    user-select: none;
  }

  .product-name {
    font-size: 14px;
    color: #2C2D34;
    margin: 0;
    padding-top: 12px;
  }

  .product-price {
    margin: 0;
  }

  .product-price .currency {
    font-size: 14px;
    font-weight: bold;
    color: #EB701D;
    margin: 0;
  }

  .product-price .amount {
    font-size: 22px;
    font-weight: bold;
    color: #EB701D;
    margin: 0;
  }

  .discount-view {
    display: inline-block;
    padding: 1px 12px;
    background-size: 100% 100%;
    background-image: url('@/assets/app/payment/discount_bk.png');
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 5px;
    font-size: 13px;
    color: #FFEAAF;
    text-align: center;
  }
</style>