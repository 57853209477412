<template>
  <div>
    <a-layout>
      <a-layout-sider width="200" style="background-color: white;">
        <a-menu :selectedKeys="[selectedKeys]" mode="inline" theme="dark" :items="slideMenus" @select="handleSelect">
        </a-menu>
      </a-layout-sider>
      <a-layout>
        <a-layout-header height="200" style="padding: 0; background: #fff">
          <div style="display: flex; flex-direction: row; align-items: center;">
            <div style="flex-grow: 1; text-align: center; font-size: 18px; color: #000">易播后台管理系统</div>
            <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent style="margin-right: 20px;">
                {{ userAccount }}
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="handleSetPassword">
                    <a href="javascript:;">修改密码</a>
                  </a-menu-item>
                  <a-menu-item @click="handleLogout">
                    <a href="javascript:;">注销登录</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </a-layout-header>
        <a-layout-content :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }">
          <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-modal v-model:open="showSetPassword" title="修改密码" @ok="handlePasswordSave">
      <a-space direction="vertical" style="width: 100%;">
        <a-input v-model:value="inputPasswordInfo.oldPassword" placeholder="输入旧密码"></a-input>
        <a-input v-model:value="inputPasswordInfo.newPassword" placeholder="输入新密码"></a-input>
      </a-space>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import { message } from 'ant-design-vue';
import { DownOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    DownOutlined
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userAccount = ref('');
    const selectedKeys = ref('');
    const slideMenus = ref([]);
    const showSetPassword = ref(false);
    const inputPasswordInfo = ref({ oldPassword: '', newPassword: '' });

    const fetchAuthPermits = async () => {
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/info');
        console.log('response: ', response);

        const responseData = response.data;
        if (responseData.succeed) {
          userAccount.value = responseData.data.account;
          const permits = responseData.data.permits;

          if (permits.includes('create_cdk')) {
            slideMenus.value.push({ key: '/admin/membership', label: '会员资格' });
          }
          if (permits.includes('edit_role')) {
            slideMenus.value.push({ key: '/admin/role', label: '角色管理' });
          }

          if (slideMenus.value.length > 0) {
            let defaultKey = route.path;
            if (defaultKey === '/admin') {
              defaultKey = slideMenus.value[0].key;
            }

            selectedKeys.value = defaultKey;
            router.push(selectedKeys.value);
          }
          else {
            router.push('/admin');
          }
        }
      } catch (error) {
        console.error('error: ', error);

        const response = error.response;
        if (response.status === 401 || response.status === 403) {
          router.push('/admin/login');
          message.warn({
            content: '登录已过期，请重新登录',
            duration: 5
          });
        }
      }
    }

    const handleSelect = ({ key }) => {
      selectedKeys.value = key;
      router.push(key);
    };

    const handleSetPassword = () => {
      showSetPassword.value = true;
    };

    const handlePasswordSave = async () => {
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/password', {
          params: {
            old_password: inputPasswordInfo.value.oldPassword,
            new_password: inputPasswordInfo.value.newPassword
          }
        });
        console.log('response: ', response);

        const responseData = response.data;
        if (responseData.succeed) {
          message.success('密码修改成功');
          showSetPassword.value = false;
        }
      } catch (error) {
        console.error('error: ', error);

        const response = error.response;
        if (response.status === 403) {
          message.error('旧密码错误');
        } else {
          message.error('密码修改失败');
        }
      }
    }

    const handleLogout = () => {
      localStorage.removeItem('authToken');
      router.push('/admin/login');
    };

    onMounted(() => {
      fetchAuthPermits();
    })

    return {
      userAccount,
      selectedKeys,
      slideMenus,
      showSetPassword,
      inputPasswordInfo,
      handleSelect,
      handleSetPassword,
      handlePasswordSave,
      handleLogout,
    };
  }
})
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
