import { createRouter, createWebHistory } from 'vue-router';
import AppLogin from '@/views/app/Login.vue';
import AppLoginCallback from '@/views/app/LoginCallback.vue';
import AppPayment from '@/views/app/Payment.vue';
import AdminView from '@/views/admin/IndexView.vue';
import AdminMembership from '@/views/admin/MembershipView.vue';
import AdminRole from '@/views/admin/RoleView.vue';
import AdminLogin from '@/views/admin/LoginView.vue';

const routes = [
  {
    path: '/',
    component: () => import('@/views/HomePage.vue'),
    meta: { title: '易播-直播助手' }
  },
  {
    path: '/app',
    redirect: '/app/login',
    children: [
      {
        path: 'login',
        component: AppLogin
      },
      {
        path: 'login/callback',
        component: AppLoginCallback
      },
      {
        path: 'payment',
        component: AppPayment
      }
    ]
  },
  {
    path: '/admin',
    component: AdminView,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'membership',
        component: AdminMembership
      },
      {
        path: 'role',
        component: AdminRole
      }
    ]
  },
  {
    path: '/admin/login',
    component: AdminLogin
  }
];

const router = createRouter({ history: createWebHistory(), routes });
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('authToken');
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    next({ path: '/admin/login' });
  } else {
    next();
  }

  if (to.meta.title) {
    window.document.title = to.meta.title;
  }
  else {
    window.document.title = '易播';
  }
});

export default router;
