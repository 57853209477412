<template>
  <div>
    <div class='centered-text'>{{ loginMessage }}</div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import axios from 'axios';

export default {
  name: 'AppLoginCallback',
  setup() {
    const loginMessage = ref('')

    const route = useRoute()
    const code = route.query.code
    const stateQuery = route.query.state
    const sign = route.query.sign

    const performLogin = (success, data = '') => {
      if (window.app && typeof window.app.login === 'function') {
        window.app.login(success, data);
      }
      else if (window.parent) {
        window.parent.postMessage({
          type: 'login',
          succeed: success,
          data: data
        }, '*');
      }
      else {
        console.log('error');
      }
    }

    onMounted(async () => {
      if (stateQuery === sign) {
        loginMessage.value = '扫码成功，正在登录...'
        console.log(code)

        try {
          const response = await axios.get('https://yibo.yu007.com/api/user/login', {
            params: { code: code }
          })
          const data = response.data

          if (data.succeed) {
            loginMessage.value = '登录成功'
            const dataString = JSON.stringify(data)
            console.log(dataString)
            performLogin(true, dataString)
          } else {
            loginMessage.value = '登录失败：' + data.message
            performLogin(false)
          }
        } catch (error) {
          loginMessage.value = '登录失败：网络错误'
          performLogin(false)
        }
      } else {
        loginMessage.value = '验证失败'
        performLogin(false)
      }
    })

    return {
      loginMessage
    }
  }
}
</script>

<style scoped>
.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 16px;
}
</style>
