<template>
    <div v-if="scriptLoaded" id="login_container"></div>
    <div v-else class="centered-text">加载中</div>
</template>

<script>
import { nextTick } from 'vue';

export default {
    name: 'WxLogin',
    data() {
        return {
            scriptLoaded: false
        };
    },
    props: {
        appId: String,
        redirectUri: String,
        theme: {
            type: String,
            default: 'black'
        },
        href: {
            type: String,
            default: ''
        },
        selfRedirect: {
            type: String,
            default: 'default'
        }
    },
    mounted() {
        this.loadWxLoginScript().then(() => {
            this.scriptLoaded = true;
            nextTick(() => {
                this.initWxLogin();
            });
        });
    },
    methods: {
        loadWxLoginScript() {
            return new Promise((resolve, reject) => {
                if (document.getElementById('wxLoginScript')) {
                    resolve();
                    return;
                }
                const script = document.createElement('script');
                script.id = 'wxLoginScript';
                script.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
                script.onload = resolve;
                script.onerror = reject;
                document.head.appendChild(script);
            });
        },
        initWxLogin() {
            const state = this.createState();

            let redirect_uri = new URL(this.redirectUri);
            redirect_uri.searchParams.append('sign', state);

            new window.WxLogin({
                self_redirect: this.selfRedirect,
                id: 'login_container',
                appid: this.appId,
                scope: 'snsapi_login',
                redirect_uri: encodeURIComponent(redirect_uri.toString()),
                state: state,
                style: this.theme,
                href: this.href
            });
        },
        setTimeDateFmt(s) {
            return s < 10 ? '0' + s : s;
        },
        createState() {
            const now = new Date()
            let month = now.getMonth() + 1
            let day = now.getDate()
            let hour = now.getHours()
            let minutes = now.getMinutes()
            let seconds = now.getSeconds()
            month = this.setTimeDateFmt(month)
            day = this.setTimeDateFmt(day)
            hour = this.setTimeDateFmt(hour)
            minutes = this.setTimeDateFmt(minutes)
            seconds = this.setTimeDateFmt(seconds)
            let value = now.getFullYear().toString() + month.toString() + day + hour + minutes + seconds + (Math.round(Math.random() * 1000000)).toString();
            return value;
        }
    }
}
</script>

<style>
iframe {
    width: 100%;
    height: 100%;
}

#login_container {
    width: 100%;
    height: 100%;
}

img {
    -webkit-app-region: no-drag;
}

.centered-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 16px;
}
</style>
