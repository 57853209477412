<template>
  <div id="content" @dragstart.prevent>
    <div id="content-middle">
      <div id="product-counter">
        <div id="product-list" v-if="products.length > 0">
          <ProductItem v-for="(product, index) in products" :key="index" :product="product" :index="index"
            :isSelected="selectedIdx === index" @select="selectProduct(index)" />
        </div>
        <p style="margin: 23px 0 0 0;" v-if="products.length > 0">
          <span style="font-size: 14px; color: #2C2D34;">￥</span>
          <span style="font-size: 26px; font-weight: bold; color: #2C2D34;">{{
            parseFloat(products[selectedIdx].price).toString() }}</span>
        </p>
      </div>
      <div id="payment-way">
        <div id="qrcode-box">
          <div v-if="codeUrl">
            <qrcode-vue style="width: calc(100% - 10px); height: calc(100% - 10px); margin: 5px;"
              :value="codeUrl"></qrcode-vue>
          </div>
          <p v-else style="height: 80%; display: flex; justify-content: center; font-size: 16px; align-items: center;">
            加载中...</p>
        </div>
        <div style="display: flex; margin-top: auto; height: auto;">
          <button class="pay-way-button" @mouseover="wechatPayHovered = true" @mouseleave="wechatPayHovered = false"
            @click="wechatPay = true">
            <img :src="wechatPay ? wechatPayIconS : wechatPayHovered ? wechatPayIconH : wechatPayIcon"
              alt="Wechat Pay Button Icon" />
          </button>
          <button class="pay-way-button" @mouseover="aliPayHovered = true" @mouseleave="aliPayHovered = false"
            @click="wechatPay = false">
            <img :src="!wechatPay ? aliPayIconS : aliPayHovered ? aliPayIconH : aliPayIcon" alt="Ali Pay Button Icon" />
          </button>
        </div>
      </div>
    </div>
    <div>
      <p style="margin: 5px 0 0 0;">
        <span style="font-size: 14px; color: #2C2D34; margin-top: 0;">开通会员代表同意</span>
        <a style="font-size: 14px; color: #0090FF;" href="/app/login" target="_blank">《会员服务协议》</a>
      </p>
      <pre style="font-size: 12px; color: #575863; margin: 30px 0 0 0;">温馨提示:
如果支付成功后会员没更新，请您不要重复支付。您可以退出账号后再登录一下。
      </pre>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import QrcodeVue from 'vue-qrcode';
import axios from 'axios';
import ProductItem from '@/components/ProductItem.vue';

export default {
  name: 'AppPayment',
  components: {
    QrcodeVue,
    ProductItem
  },
  data() {
    return {
      codeUrl: null,
      wechatPayHovered: false,
      wechatPayIcon: require('@/assets/app/payment/wechat_pay.png'),
      wechatPayIconH: require('@/assets/app/payment/wechat_pay_h.png'),
      wechatPayIconS: require('@/assets/app/payment/wechat_pay_s.png'),
      aliPayHovered: false,
      aliPayIcon: require('@/assets/app/payment/ali_pay.png'),
      aliPayIconH: require('@/assets/app/payment/ali_pay_h.png'),
      aliPayIconS: require('@/assets/app/payment/ali_pay_s.png'),
      products: [],
      selectedIdx: 0,
      wechatPay: true,
      userId: null,
      orderId: null
    };
  },
  mounted() {
    const route = useRoute()
    this.userId = route.query.user_id;
    if (this.userId) {
      this.fetchProductList();
      this.pollOrderStatus();
    }
  },
  methods: {
    fetchProductList() {
      axios.get('https://yibo.yu007.com/api/product/list',)
        .then(response => {
          if (response.data.succeed) {
            console.log(response.data.data);
            this.products = response.data.data;
            this.selectProduct(0);
          } else {
            console.log(response.data.message);
          }
        })
        .catch(error => {
          console.error('Error fetching products:', error);
        });
    },
    selectProduct(index) {
      this.selectedIdx = index;
      this.codeUrl = null;
      axios.get('https://yibo.yu007.com/api/payment/buy?', {
        params: {
          user_id: this.userId,
          product_id: this.products[this.selectedIdx].id
        }
      })
        .then(response => {
          if (response.data.succeed) {
            this.codeUrl = response.data.data.code_url;
            this.orderId = response.data.data.order_id;
          } else {
            console.error('Failed to fetch QR code URL');
          }
        })
        .catch(error => {
          console.error('Error fetching QR code URL:', error);
        });
    },
    pollOrderStatus() {
      this.pollInterval = setInterval(() => {
        if (this.orderId) {
          axios.get('https://yibo.yu007.com/api/order/info', {
            params: {
              user_id: this.userId,
              order_id: this.orderId
            }
          })
            .then(response => {
              const data = response.data.data;
              if (data.status == 'completed') {
                this.handlePaymentSuccess();
                clearInterval(this.pollInterval);
              }
            })
            .catch(error => {
              console.error('Error polling order status:', error);
              clearInterval(this.pollInterval);
            });
        }
      }, 2000);
    },
    handlePaymentSuccess() {
      console.log('支付成功');
      if (window.app && typeof window.app.payment === 'function') {
        window.app.payment()
      }
      else if (window.parent) {
        window.parent.postMessage({
          type: 'payment'
        }, '*');
      }
      else {
        console.log('error');
      }
    }
  }
}
</script>

<style scoped>
#content {
  width: 618px;
  height: 456px;
  background: url('@/assets/app/payment/bk.png');
  padding: 50px 70px 0 70px;
  box-sizing: border-box;
  overflow: hidden;
}

#content-middle {
  display: flex;
  justify-content: space-between;
}

#product-list {
  width: 206px;
  height: 206px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

#payment-way {
  width: 207px;
  height: 241px;
  background: url('@/assets/app/payment/payment_way_box.png');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#qrcode-box {
  width: 190px;
  height: 189px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  background: url('@/assets/app/payment/qrcode_box.png');
}

.pay-way-button {
  width: 102px;
  height: 35px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
</style>
