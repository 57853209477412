<template>
  <div id="content" @dragstart.prevent>
    <WxLogin :appId="appId" :redirectUri="redirectUri" :theme="theme" :href="href" :selfRedirect="selfRedirect">
    </WxLogin>
  </div>
</template>

<script>
import WxLogin from '@/components/WxLogin.vue';

export default {
  name: 'AppLogin',
  components: {
    WxLogin
  },
  data() {
    const href_css = '.impowerBox .title {display: none;} .impowerBox .info {display: none;} .impowerBox .qrcode {width: 100%; height: 100%; border: none; margin:0px}';

    return {
      appId: 'wx1f925305937be2e9',
      redirectUri: 'https://yibo.yu007.com/app/login/callback',
      theme: 'black',
      href: 'data:text/css;base64,' + btoa(href_css),
      selfRedirect: true
    }
  }
}
</script>

<style scoped>
#content {
  width: 100%;
  height: 100vh;
}
</style>
