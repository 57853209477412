<style scoped>
.content {
  background-color: rgb(33, 33, 33);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 400px;
}

.login-form {
  margin-top: 20px;
  width: 100%;
}
</style>

<template>
  <div class="content">
    <div class="login">
      <div>
        <img style="width: 40px; height: 40px;" src="@/assets/admin/logo.png">
        <a style="margin-left: 10px; font-size: 20px;">易播管理后台</a>
      </div>
      <a-form class="login-form" @submit.prevent="handleSubmit">
        <a-form-item>
          <a-input v-model:value="username" placeholder="用户名" />
        </a-form-item>
        <a-form-item>
          <a-input-password v-model:value="password" placeholder="密码" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" html-type="submit">
            登录
          </a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue';
import axios from 'axios';
import { message } from 'ant-design-vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  setup() {
    const username = ref('');
    const password = ref('');
    const router = useRouter();

    const handleSubmit = async () => {
      console.log('Username:', username.value);
      console.log('Password:', password.value);
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/login', {
          params: {
            account: username.value,
            password: password.value
          }
        })
        const data = response.data

        if (data.succeed) {
          console.log('token: ', data.token);
          localStorage.setItem('authToken', data.token);
          router.push('/admin');
          message.success('登录成功');
        } else {
          message.error('登录失败: ' + data.message);
        }
      } catch (error) {
        console.error('error: ', error);

        const response = error.response;
        console.log('response: ', response);
        if (response.status === 404) {
          message.error('登录失败：网络错误');
        }
        else {
          message.error('登录失败：' + response.data.message);
        }
      }
    };

    return {
      username,
      password,
      handleSubmit
    };
  }
})
</script>
