<template>
  <div>
    <a-space direction="vertical" style="width: 100%;">
      <a-space>
        <a-button type="primary" @click="handleRefesh">刷新</a-button>
        <a-button type="primary" @click="handleAdd">新增</a-button>
      </a-space>
      <a-table :columns="columns" :data-source="roleList" bordered>
        <template #bodyCell="{ column, record }">
          <template v-if="column.dataIndex === 'operation'">
            <a-space>
              <a-button type="primary" @click="handlePermits(record)">权限</a-button>
              <a-button type="primary" @click="handlePassword(record)">改密</a-button>
              <a-button type="primary" v-if="record.disabled" @click="handleEnable(record)" danger>启用</a-button>
              <a-button type="primary" v-else-if="!record.disabled" @click="handleDisable(record)" danger>禁用</a-button>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-space>
    <a-modal v-model:open="showAdd" title="新增账号" @ok="handleAddSave">
      <a-space direction="vertical" style="width: 100%;">
        <a-input v-model:value="inputRoleInfo.account" placeholder="输入账号"></a-input>
        <a-input v-model:value="inputRoleInfo.password" placeholder="输入密码"></a-input>
      </a-space>
    </a-modal>
    <a-modal v-model:open="showSetPassword" title="修改密码" @ok="handlePasswordSave">
      <a-space direction="vertical" style="width: 100%;">
        <a-input v-model:value="inputRoleInfo.password" placeholder="输入密码"></a-input>
      </a-space>
    </a-modal>
    <a-modal v-model:open="showSetPermits" title="修改权限" footer>
      <a-space direction="vertical" style="width: 100%;">
        <a-checkbox v-model:checked="tempPermits.permits.create_cdk"
          @change="toggleCreateCdk($event, 'create_cdk')">创建cdk</a-checkbox>
      </a-space>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue';
import axios from 'axios';
import { message } from 'ant-design-vue';

export default defineComponent({
  setup() {
    const showAdd = ref(false);
    const showSetPassword = ref(false);
    const showSetPermits = ref(false);
    const inputRoleInfo = ref({});
    const tempPermits = ref({ id: '', permits: { create_cdk: false } });
    const roleList = reactive([]);

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        width: '10%',
      },
      {
        title: '用户id',
        dataIndex: 'id',
        width: '25%',
      },
      {
        title: '账号',
        dataIndex: 'account',
        width: '25%',
      },
      {
        title: '操作',
        dataIndex: 'operation',
      },
    ];

    const fetchRoles = async () => {
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/role/list');
        console.log('response: ', response);

        if (response.status === 200) {
          roleList.length = 0;
          for (let i = 0; i < response.data.data.length; i++) {
            roleList.push({
              index: i + 1,
              id: response.data.data[i].id,
              account: response.data.data[i].account,
              disabled: response.data.data[i].disabled,
            });
          }
        } else {
          message.error('获取角色列表失败');
        }
      } catch (error) {
        console.error('error: ', error);
      }
    };

    const handleRefesh = () => {
      roleList.length = 0;
      fetchRoles();
    };

    const handleAdd = () => {
      console.log('handleAdd');
      showAdd.value = true;
    };

    const handleAddSave = async () => {
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/role/add', { params: inputRoleInfo.value });
        console.log('response: ', response);

        inputRoleInfo.value = { account: '', password: '' };
        showAdd.value = false;

        message.success('添加成功');
      } catch (error) {
        console.error('error: ', error);

        const response = error.response;
        if (response.status === 409) {
          message.error('添加失败, 此账号已存在');
        }
        else {
          message.error('添加失败');
        }
      }
    };

    const handlePasswordSave = async () => {
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/role/password', {
          params: {
            admin_id: inputRoleInfo.value.id,
            password: inputRoleInfo.value.password
          }
        });
        console.log('response: ', response);

        inputRoleInfo.value = {};
        showSetPassword.value = false;

        message.success('修改密码成功');
      } catch (error) {
        console.error('error: ', error);

        const response = error.response;
        if (response.status === 409) {
          message.error('修改密码失败, 此账号已存在');
        }
        else {
          message.error('修改密码失败');
        }
      }
    }

    const handlePermits = async (record) => {
      showSetPermits.value = true;
      tempPermits.value.id = record.id;
      tempPermits.value.permits.create_cdk = false;
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/role/permits', {
          params: {
            admin_id: record.id,
          }
        });
        console.log('response: ', response);
        const permits = response.data.data;
        if (permits.includes('create_cdk'))
          tempPermits.value.permits.create_cdk = true;
      } catch (error) {
        console.error('error: ', error);
      }
    };

    const handlePassword = (record) => {
      showSetPassword.value = true;
      inputRoleInfo.value.id = record.id;
    }

    const handleEnable = async (record) => {
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/role/enable', {
          params: {
            admin_id: record.id,
          }
        });
        console.log('response: ', response);

        record.disabled = false;
        message.success('启用成功');
      } catch (error) {
        console.error('error: ', error);
        message.error('启用shibai');
      }
    }

    const handleDisable = async (record) => {
      try {
        const response = await axios.get('https://yibo.yu007.com/api/admin/role/disable', {
          params: {
            admin_id: record.id,
          }
        });
        console.log('response: ', response);

        record.disabled = true;
        message.success('启用成功');
      } catch (error) {
        console.error('error: ', error);
        message.error('启用失败');
      }
    }

    const toggleCreateCdk = async (event, permit) => {
      try {
        const checked = event.target.checked;
        const response = await axios.get('https://yibo.yu007.com/api/admin/role/' + (checked ? 'approve' : 'revoke'), {
          params: {
            admin_id: tempPermits.value.id,
            permit: permit
          }
        });
        console.log('response: ', response);
        message.success('启用成功');
      } catch (error) {
        console.error('error: ', error);
        message.error('启用失败');
      }
    }

    onMounted(() => {
      fetchRoles();
    })

    return {
      showAdd,
      showSetPassword,
      showSetPermits,
      inputRoleInfo,
      columns,
      roleList,
      tempPermits,
      handleRefesh,
      handleAdd,
      handleAddSave,
      handlePasswordSave,
      handlePermits,
      handlePassword,
      handleEnable,
      handleDisable,
      toggleCreateCdk,
    };
  }
})

</script>
